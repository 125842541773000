import React, { useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import PassageSelection from './components/PassageSelection';
import WordGuessGame from './components/WordGuessGame';
import {PassagesProvider} from './contexts/PassagesContext';

/* colors
#060606 black
#434948 slight grey
#35383b dark grey
*/

// Main app component
const App = () => {
  useEffect(() => {
    document.title = "GPT-Poor";
  }, []);


  return (
    <PassagesProvider>
        <Router>
        <div className="App">
        <Header/>
        <Routes>
          <Route path="/passage/:passageId" element={
            <WordGuessGame
              initialContextSize={6} 
              numEpochs={3} 
            />
          }>
          </Route>
          <Route path="/" element={
            <>
              <PassageSelection/>
            </>
          }>
          </Route>
        </Routes>
        </div>
        </Router>
    </PassagesProvider>
  );
};

export default App;
