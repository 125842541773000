import React, { useState, useEffect, createContext } from "react";


const PassagesContext = createContext();


const PassagesProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [passages, setPassages] = useState([]);
  const [selectedPassage, setSelectedPassage] = useState(null);

  useEffect(() => {
    fetch('/.netlify/functions/passages-crud')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error: ' + response.statusText);
        }
      })
      .then(data => {
        setPassages(data.map(passage => passage.data));
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []); // Empty array means this effect runs once on mount

  const titleMapping = passages.reduce((acc, passage, index) => {
    const title = passage.title.replace(/ /g, "").toLowerCase();
    acc[title] = index;
    return acc;
  }, {});

  const contextValue = { 
    loading,
    error,
    passages,
    titleMapping,
    selectedPassage,
    setSelectedPassage
  };

  return (
    <PassagesContext.Provider value={contextValue}>
      {children}
    </PassagesContext.Provider>
  );
};

export default PassagesContext;
export { PassagesProvider };
