import { Link } from "react-router-dom";
import React, { useEffect, useContext } from "react";
import PassagesContext from '../contexts/PassagesContext';

const PassageSelection = () => {
  const { passages, setSelectedPassage } = useContext(PassagesContext);

  useEffect(() => {
    setSelectedPassage(null);
  }, [setSelectedPassage]);

  return (
    <div>
      <h1>Select a Passage</h1>
      {passages.map((passage) => (
        <Link
          className="button-link"
          key={passage.title}
          to={`/passage/${passage.title.replace(/ /g, "").toLowerCase()}`}
          style={{ display: "block", marginBottom: "10px" }}
        >
          {passage.title}
        </Link>
      ))}
    </div>
  );
};

export default PassageSelection;
