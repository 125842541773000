import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import PassagesContext from '../contexts/PassagesContext';

const WordGuessGame = ({ initialContextSize, numEpochs }) => {
  // the passageId is passed in as a URL parameter
  const { passageId } = useParams();
  // the passages and titleMapping are passed in from the PassagesContext
  const { passages, titleMapping, setSelectedPassage } = useContext(PassagesContext);
  const idx = titleMapping[passageId];

  // the passage is the text of the passage with the given passageId
  const passage = passages[idx] || '';

  const text = passage.passage.map(token => token.word).join(" ");
  setSelectedPassage(text);
  const [tokenIndex, setTokenIndex] = useState(initialContextSize);
  console.log('tokenIndex')
  console.log(tokenIndex)
  console.log('passage')
  console.log(passage.passage)
  const [recommendations, setRecommendations] = useState(
    getNextRecommendations(passage.passage, tokenIndex)
  );
  const [epoch] = useState(0);
  const [currentScore, setCurrentScore] = useState(0);
  const [scores] = useState([]);
  // the tokens are the words in the passage, with the following properties:
  // word: the word itself
  // guessed: whether the word has been guessed
  // correct: whether the guess was correct
  // init: whether the word is part of the initial context
  const [tokens, setTokens] = useState(
    text.split(" ").map((token, index) => ({
      word: token,
      guessed: false,
      correct: false,
      init: index < initialContextSize
    }))
  );
  const [gameOver, setGameOver] = useState(false);
  
  // Handle the guess submission
  const handleSubmit = (guess) => {
    const regex = /[.,/#!$%^&*;:{}=_`~()"'-]/g;
    const guessProcessed = guess.toLowerCase().replace(regex, "");
    const recommendations = getNextRecommendations(passage.passage, tokenIndex + 1)
    setRecommendations(shuffle(recommendations));

    if (tokenIndex + 1 >= passage.passage.length) {
      setGameOver(true);
    }

    setTokenIndex(tokenIndex + 1);
    const nextToken = tokens.find(token => !token.guessed && !token.init);
    const nextWordProcessed = nextToken ? nextToken.word.toLowerCase().replace(regex, "") : '';

    // using newScore to track instant updates to score, since setCurrentScore is async
    let newScore = currentScore;
    if (guessProcessed !== nextWordProcessed) {
      newScore += 1;
      setCurrentScore(newScore);
    }

    setTokens(
      tokens.map((token, index) =>
        index === tokens.findIndex(token => !token.guessed && !token.init)
          ? { ...token, guessed: true, correct: guessProcessed === nextWordProcessed, init: false }
          : token
      )
    );
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto' }}>
      <h2 style={{textAlign: 'left'}}>{passage.title}</h2>
      <h3 style={{textAlign: 'left'}}>Epoch {epoch + 1}</h3>
      <p>
        {tokens.filter(token => (token.init || token.guessed)).map((token, index) => (
          <span
            key={index}
            style={{ color: !token.init && token.guessed ? (token.correct ? 'green' : 'red') : 'white' }}
          >
            {token.word + ' '}
          </span>
        ))}
      </p>
      <ul className="recommendation-list">
        {recommendations.map((recommendation, index) => (
          <button key={index} onClick={() => handleSubmit(recommendation)}>
            {recommendation}
          </button>
        ))}
      </ul>
      {gameOver && (
        <h3>Finished!</h3>
      )}
      <p style={{textAlign:'left'}}>Current Loss: {currentScore}</p>
      {scores.length > 0 && 
        <div>
          <h3>Past Losses:</h3>
          {scores.map((scoreObj, index) => (
            <p key={index}>Epoch {scoreObj.epoch}: {scoreObj.score}</p>
          ))}
        </div>
      }
    </div>
  );
};

function shuffle(array) {
  if (!array) {
    return [];
  }

  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function getNextRecommendations(passage, tokenIndex) {
  if(!passage || !passage[tokenIndex]) {
    return [];
  }
  return passage[tokenIndex].recommendations;
}

export default WordGuessGame;
