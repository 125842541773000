
const Header = () => {
  return (
    <div className="header" style={{padding:'0px'}}>
      <h1>Causal Learn</h1>
    </div>
  );
};

export default Header;
